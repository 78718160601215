import React from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { Hero } from "components/Hero";
import TicketShop from "components/TicketShop";
import { Page } from "components/Page";

function SocietyPage(p) {
  const { name, scriptString, description } = p.pageContext;

  return (
    <Page light={true} fixed={true} locationSearch={p.location.search}>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={`${name} | Exeter Students' Guild Events`}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: `https://events.exeterguild.com${p.location.pathname}`,
          },
          {
            property: `og:title`,
            content: `${name} | Exeter Students' Guild Events`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero color="ruby" type="society">
        <div className="society-hero-card society-hero-card--dark">
          <h1>{name}</h1>
        </div>
      </Hero>
      {description && (
        <div className="society-banner">
          <p>{description}</p>
        </div>
      )}
      <Content>
        <TicketShop scriptString={scriptString} />
      </Content>
    </Page>
  );
}

export default SocietyPage;
